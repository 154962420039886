import React from 'react'
import styled from '@emotion/styled'
import theme from './theme'

const TagStyle = styled.span`
  ${theme.font.small};
  display: inline-block;
  padding: 0.125rem 0.25rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  ${props => {
    if (props.type === 'light') {
      return `
        background-color: #fff;
        color: ${theme.color.dark};
      `
    }
    return `
      background-color: ${theme.color.dark};
      color: #fff;
    `
  }};
`

export default props => {
  return <TagStyle type={props.type}>{props.children}</TagStyle>
}
