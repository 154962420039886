import styled from '@emotion/styled'

const Container = styled.div`
  padding: 1rem;
  max-width: 50ch;
  ${props => {
    if (props.space === 'large') {
      return `
        padding: 2.6rem 1.2rem;
        @media (min-width: 780px) {
          padding: 4rem 3rem;
        }

        @media (min-width: 1220px) {
          padding: 5rem 4rem;
        }
      `
    }
  }};
`

export default Container
