import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import theme from './theme'

const CoverPic = styled.div`
  background-image: ${props => {
    return `url(${props.cover})`
  }};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-bottom: 70.0280112%;
  transition-duration: 4s;
  transition-timing-function: ease-out;
  transition-property: transform;
  transform-origin: center center;
`

const PostThumb = props => {
  const cover =
    props.post.frontmatter.cover || `/img/cover/${props.post.fields.name}.jpg`

  const color = props.post.frontmatter.color || theme.color.pink

  return (
    <Link
      to={props.post.fields.slug}
      css={css`
        display: grid;
        grid-template-rows: auto 1fr;
        text-decoration: none;
        border-radius: 1px;
        overflow: hidden;

        &:hover h2 {
          color: ${color};
        }

        &:hover p {
          opacity: 1;
        }

        &:hover ${CoverPic} {
          transform: scale3d(1.1, 1.1, 1);
        }
      `}
    >
      <CoverPic cover={cover} />

      <div
        css={css`
          background: #1f1d25;
          color: #fff;
          padding: 1rem;
          padding-bottom: 0.5rem;
          z-index: 2;
        `}
      >
        <h2
          css={css`
            font-weight: 500;
            transition-duration: 0.5s;
            transition-property: color;
            @media (max-width: 600px) {
              /* font-size: 0.95rem; */
            }

            @media (max-width: 1220px) {
              font-size: 0.9rem;
            }
          `}
        >
          {props.post.frontmatter.title}
        </h2>
        <p
          css={css`
            opacity: 0.75;
            // transition-duration: 0.05s;
            // transition-property: opacity;
          `}
        >
          {props.post.frontmatter.description}
        </p>
      </div>
    </Link>
  )
}

export default PostThumb
