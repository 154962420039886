import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Banner from '../components/partials/banner'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Button from '../components/Button'
import Tag from '../components/Tag'
import Container from '../components/Container'
import PostThumb from '../components/PostThumb'

const twoColumnBreak = '780px'
const MasteryGames = styled.div`
  display: grid;
  @media (min-width: ${twoColumnBreak}) {
    grid-template-columns: 1fr 1fr;
  }
`

const LatestPost = styled.div`
  display: grid;
  justify-items: center;

  /* padding: 1.3rem; */
  @media (min-width: ${twoColumnBreak}) {
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    /* grid-column-gap: 2.5rem; */
    /* padding: 2.5rem; */
  }
  /* @media (min-width: 1200px) { */
  /* grid-column-gap: 3rem; */
  /* padding: 3rem; */
  /* } */
`

export default ({ data }) => {
  const posts = data.allMdx.edges.filter(item => {
    return !item.node.fields.slug.includes('flexbox/challenge')
  })

  const latestPost = posts[0].node
  const latestPostImage = latestPost.frontmatter.pic || `/img/${latestPost.fields.name}.jpg`;

  //don't show feature post twice
  posts.splice(0, 1)

  return (
    <Layout>
      {/* <div
        css={css`
          background: #2c2e36;
          color: #eaeaeb;
          padding: 10rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <Logo />
        <span
          css={css`
            margin-top: 2rem;
          `}
        >
          level up your UI chops
        </span>
      </div> */}

      <Banner />

      <LatestPost>
        <div
          space="large"
          css={css`
            align-self: center;
            padding: 1.2rem;
            @media (min-width: ${twoColumnBreak}) {
              order: 1;
            }
          `}
        >
          <img css={css``} src={latestPostImage} alt="latest post" />
        </div>

        <Container space="large">
          <h1>{latestPost.frontmatter.title}</h1>
          <p>{latestPost.frontmatter.description}</p>
          <Button to={latestPost.fields.slug}>Read Article</Button>
        </Container>
      </LatestPost>

      <MasteryGames>
        <div
          css={css`
            background-color: #1f123f;
            background-image: url(/gc/master-it.jpg);
            /* background-image: url(/gc/grid-game.jpg); */
            background-repeat: no-repeat;
            background-size: cover;
            /* background-position: center right; */
            background-position: center left;
            min-height: 400px;
            /* transform: scaleX(-1); */
          `}
        />

        <div
          css={css`
            display: grid;
            justify-content: center;
            background: #1f123f;
            color: #fff;
            @media (min-width: ${twoColumnBreak}) {
              justify-content: start;
            }
          `}
        >
          <Container space="large">
            <Tag type="light">Mastery Game</Tag>
            <h1>Grid Critters</h1>
            <p>
              The future of frontend layout is here, and it's called CSS Grid.
              Once you master this amazing tech you'll be able to build crazy
              awesome UIs in very little time and code. Don't spend the next ten
              years guessing and worrying. Master every aspect of Grid right
              now, and save an adorable alien species while you're at it.
            </p>
            <Button to="https://gridcritters.com" color="#90889f">
              Learn More
            </Button>
          </Container>
        </div>

        <div
          css={css`
            background-color: #18171f;
            background-image: url(/fbz/flexbox-game.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top right;
            min-height: 400px;
            @media (min-width: ${twoColumnBreak}) {
              order: 1;
            }
          `}
        />

        <div
          css={css`
            display: grid;
            justify-content: center;
            background: #18171f;
            color: #fff;
            @media (min-width: ${twoColumnBreak}) {
              justify-content: end;
            }
          `}
        >
          <Container space="large">
            <Tag type="light">Mastery Game</Tag>
            <h1>Flexbox Zombies</h1>
            <p>
              Flexbox is incredibly powerful. But it's also crazy hard to
              master. So we all end up depending on a cheat sheet and guessing
              in the dev tools. Enough of that! Time to master it once and for
              all, in a way that actually sticks, so you can build any layout
              you can imagine with flexbox.
            </p>
            <Button to="https://flexboxzombies.com" color="#8f8e92">
              Learn More
            </Button>
          </Container>
        </div>
      </MasteryGames>

      <div
        css={css`
          display: grid;
          justify-content: center;
          padding: 2.6rem 1.2rem;
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
          grid-column-gap: 1.3rem;
          grid-row-gap: 2.4rem;

          @media (min-width: 600px) {
            padding: 4.2rem 2.6rem;
          }

          @media (min-width: 780px) {
            grid-column-gap: 1.3rem;
            grid-template-columns: repeat(auto-fill, minmax(357px, 1fr));
          }

          @media (min-width: 1220px) {
            padding: 6.2rem 4.6rem;
          }
        `}
      >
        {posts.map(({ node }) => {
          return <PostThumb key={node.id} post={node} />
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { draft: { ne: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            cover
            pic
            description
            color
          }
          fields {
            slug
            name
          }
        }
      }
    }
  }
`
